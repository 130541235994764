import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { AnmeldungForm_, anmeldungsFormMapStateToProps, anmeldungsFormMapDispatchToProps }
  from '../../lib/User/components/Anmeldung'
import SubHeader from '../../App/common/Header/SubHeader'
import { InputLeonardo } from '../../App/common/Form/Form'
import Translate from '../../lib/App/common/translator/components/translate'

const AnmeldungLeonardo = props => {
  const translateW = <Translate id={'Anmeldung/wiederherstellen'}>Mot de passe oublié?</Translate>
  const translateR = <Translate id={'Anmeldung/registrieren'}>Vous n’avez pas encore de compte?</Translate>

  return (
    <Fragment>
      <SubHeader title='Login' description='Connectez-vous avec vos identifiants ou avec votre accès exclusif.' translateId={'Anmeldung/'} />
      <section className='group bk-first'>
        <AnmeldungFormLeonardo history={props.history} />
        <ul className='group navoptions'>
          <li><Link to='/passwort/wiederherstellen'>{translateW}</Link></li>
          <li><Link to='/registrieren'>{translateR}</Link></li>
        </ul>
      </section>
    </Fragment>
  )
}

export default AnmeldungLeonardo

export class AnmeldungFormLeonardo_ extends AnmeldungForm_ {
  componentWillReceiveProps (nextProps) {
    if (nextProps.user !== null) {
      this.props.history.push('/konfigurationen')
    }
  }

  getError () {
    const translateLoginFail = <Translate id={'Anmeldung/error'}>Ungültiges Login...</Translate>

    return this.props.feedback.login === false && this.state.formSend
      ? <div className='error'><i className='fas fa-bell' /> {translateLoginFail}</div>
      : null
  }

  getForm () {
    const translateLoginButton = <Translate id={'Anmeldung/abschicken'}>Inscription</Translate>

    return (
      <div className='columns large-12 medium-12 small-12'>
        <div className='group'>
          <ol>
            <InputLeonardo
              errorClass='error'
              isValid={isValid => this.updateIsValid('email', isValid)}
              getValue={value => this.updateValue('email', value)}
              showError={this.state.form.showError}
              id='loginEmail'
              displayName='E-Mail'
              type='email'
              title='Veuillez saisir votre titre'
              translateId='Anmeldung/'
              required />
            <InputLeonardo
              errorClass='error'
              isValid={isValid => this.updateIsValid('password', isValid)}
              getValue={value => this.updateValue('password', value)}
              showError={this.state.form.showError}
              id='loginPassword'
              displayName='Mot de passe'
              type='password'
              title='Le mot de passe doit contenir au moins 8 caractères et doit être composé de lettres majuscules, de lettres minuscules et de chiffres'
              pattern='^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,}$'
              translateId='Anmeldung/'
              required />
            <li className='bk-submit'><button className='btn btn-inverted' onClick={this.handleSubmit}>{translateLoginButton}</button></li>
          </ol>
        </div>
      </div>
    )
  }

  render () {
    const error = this.getError()
    const form = this.getForm()
    return (
      <form className='group form-login' noValidate>
        <fieldset className='group row'>
          {error}
          {form}
        </fieldset>
      </form>
    )
  }
}

const AnmeldungFormLeonardo =
  connect(anmeldungsFormMapStateToProps, anmeldungsFormMapDispatchToProps)(AnmeldungFormLeonardo_)
