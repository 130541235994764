import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  RegistrierungForm_,
  mapStateToProps,
  mapDispatchToProps,
} from "../../lib/User/components/Registrierung";
import SubHeader from "../../App/common/Header/SubHeader";
import { InputLeonardo, DropdownLeonardo } from "../../App/common/Form/Form";
import Translate from "../../lib/App/common/translator/components/translate";

const RegistrierungLeonardo = (props) => {
  return (
    <Fragment>
      <SubHeader
        title="S’inscrire"
        description="Créez un compte utilisateur. Vous pouvez ainsi enregistrer la configuration et la consulter plus tard."
        translateId={"Registrieren/"}
      />
      <section className="group bk-first">
        <RegistrierungFormLeonardo history={props.history} />
      </section>
    </Fragment>
  );
};

export default RegistrierungLeonardo;

export class RegistrierungFormLeonardo_ extends RegistrierungForm_ {
  getError() {
    const translate = (
      <Translate id="Registrieren/error">
        Il y a eu une erreur. S'il vous plaît, vérifiez vos entrées...
      </Translate>
    );

    return this.props.feedback.registration === false ? (
      <div className="error">
        <i className="fas fa-bell" /> {translate}
      </div>
    ) : null;
  }

  getSuccessOrForm() {
    const translate = (
      <Translate id="Registrieren/success">
        Vous allez recevoir prochainement un lien pour terminer votre
        inscription.
      </Translate>
    );

    return this.props.feedback.registration === true ? (
      <div className="success">
        <i className="far fa-check-circle" /> {translate}
      </div>
    ) : (
      this.getForm()
    );
  }

  getForm() {
    const optionen = this.getGenderList();
    return (
      <div className="columns large-12 medium-12 small-12">
        <div className="group">
          <ol>
            <InputLeonardo
              errorClass="error"
              isValid={(isValid) => this.updateIsValid("email", isValid)}
              getValue={(value) => this.updateValue("email", value)}
              showError={this.state.form.showError}
              type="email"
              displayName="E-Mail"
              id="registrationEmail"
              title="Veuillez saisir une adresse e-mail valable"
              translateId={"User/Profil/"}
              required
            />
          </ol>
          <ol className="group">
            <InputLeonardo
              fieldClass={"group column large-6 medium-6 small-12"}
              errorClass="error"
              isValid={(isValid) => this.updateIsValid("password", isValid)}
              getValue={(value) => this.updateValue("password", value)}
              showError={this.state.form.showError}
              id="registrationPassword"
              displayName="Mot de passe"
              type="password"
              translateId={"User/Profil/"}
              title="Le mot de passe doit contenir au moins 8 caractères et doit être composé de lettres majuscules, de lettres minuscules et de chiffres"
              pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,}$"
              required
            />
            <InputLeonardo
              fieldClass={"group column large-6 medium-6 small-12"}
              errorClass="error"
              isValid={(isValid) =>
                this.updateIsValid("passwordConfirm", isValid)
              }
              getValue={(value) => this.updateValue("passwordConfirm", value)}
              showError={this.state.form.showError}
              id="registrationPasswordConfirm"
              displayName="Confirmer le mot de passe"
              type="password"
              translateId={"User/Profil/"}
              title="Le mot de passe ne correspond pas"
              pattern={
                "^" +
                this.patternValueEscape(this.state.form.values.password) +
                "$"
              }
              required
            />
          </ol>
          <ol>
            <li>
              <DropdownLeonardo
                errorClass="error"
                isValid={(isValid) => this.updateIsValid("title", isValid)}
                getValue={(value) => this.updateValue("title", value)}
                showError={this.state.form.showError}
                id="registrationTitle"
                pleaseSelectTitle="Titre"
                displayName="Titre"
                translateId={"User/Profil/"}
                title="Veuillez entrer votre titre"
                optionen={optionen}
                required
              />
            </li>
            <InputLeonardo
              errorClass="error"
              isValid={(isValid) => this.updateIsValid("nameFirst", isValid)}
              getValue={(value) => this.updateValue("nameFirst", value)}
              showError={this.state.form.showError}
              id="registrationNameFirst"
              displayName="Prénom"
              translateId={"User/Profil/"}
              title="Veuillez saisir votre prénom"
              required
            />
            <InputLeonardo
              errorClass="error"
              isValid={(isValid) => this.updateIsValid("nameLast", isValid)}
              getValue={(value) => this.updateValue("nameLast", value)}
              showError={this.state.form.showError}
              id="registrationNameLast"
              displayName="Nom"
              translateId={"User/Profil/"}
              title="Veuillez saisir votre nom"
              required
            />
            <InputLeonardo
              errorClass="error"
              isValid={(isValid) =>
                this.updateIsValid("addressStreet", isValid)
              }
              getValue={(value) => this.updateValue("addressStreet", value)}
              showError={this.state.form.showError}
              id="registrationAddressStreet"
              displayName="Rue"
              translateId={"User/Profil/"}
              title="Veuillez saisir votre rue"
              required
            />
          </ol>
          <ol className="group">
            <InputLeonardo
              fieldClass={"group column large-6 medium-6 small-12"}
              errorClass="error"
              isValid={(isValid) => this.updateIsValid("addressPlace", isValid)}
              getValue={(value) => this.updateValue("addressPlace", value)}
              showError={this.state.form.showError}
              id="registrationPlace"
              displayName="emplacement"
              translateId={"User/Profil/"}
              title="Veuillez saisir votre emplacement"
              required
            />
            <InputLeonardo
              fieldClass={"group column large-6 medium-6 small-12"}
              errorClass="error"
              isValid={(isValid) => this.updateIsValid("addressZip", isValid)}
              getValue={(value) => this.updateValue("addressZip", value)}
              showError={this.state.form.showError}
              id="registrationAddressZip"
              displayName="Numéro postal d'acheminement"
              translateId={"User/Profil/"}
              title="Veuillez saisir votre numéro postal d’acheminement"
              required
            />
          </ol>
          <ol>
            <InputLeonardo
              errorClass="error"
              isValid={(isValid) =>
                this.updateIsValid("addressCountry", isValid)
              }
              getValue={(value) => this.updateValue("addressCountry", value)}
              showError={this.state.form.showError}
              id="registrationAddressCountry"
              displayName="Pays"
              translateId={"User/Profil/"}
              title="Veuillez saisir votre pays"
              required
            />
            <InputLeonardo
              errorClass="error"
              isValid={(isValid) => this.updateIsValid("phoneNr", isValid)}
              getValue={(value) => this.updateValue("phoneNr", value)}
              showError={this.state.form.showError}
              id="registrationPhoneNr"
              displayName="Téléphone"
              translateId={"User/Profil/"}
              title="Veuillez saisir votre numéro de téléphone"
              required
            />
            <InputLeonardo
              errorClass="error"
              isValid={(isValid) => this.updateIsValid("companyName", isValid)}
              getValue={(value) => this.updateValue("companyName", value)}
              showError={this.state.form.showError}
              id="registrationCompanyName"
              displayName="Raison sociale"
              translateId={"User/Profil/"}
              title="Veuillez saisir votre entreprise de travail"
            />
            <li className="bk-submit">
              <button className="btn btn-inverted" onClick={this.handleSubmit}>
                <Translate id={"Registrieren/abschicken"}>S’inscrire</Translate>
              </button>
            </li>
          </ol>
        </div>
      </div>
    );
  }

  render() {
    const error = this.getError();
    const successOrForm = this.getSuccessOrForm();
    return (
      <Fragment>
        <form className="group" noValidate>
          <fieldset className="group row">
            {error}
            {successOrForm}
          </fieldset>
        </form>
        <ul className="group navoptions">
          <li>
            <Link to={"anmelden"}>
              <Translate id={"Registrieren/bereits-registriert"}>
                Avez-vous déjà un compte?
              </Translate>
            </Link>
          </li>
        </ul>
      </Fragment>
    );
  }
}

const RegistrierungFormLeonardo = connect(
  mapStateToProps,
  mapDispatchToProps
)(RegistrierungFormLeonardo_);
