import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import * as actions from '../../action'
import ResultRow from './components/ResultRow'
import ResultDetail from './components/ResultDetail'
import Isometrie from './../../../App/common/Isometrie/Isometrie'
import Translate from '../../../lib/App/common/translator/components/translate'
import { bindActionCreators } from 'redux'

class SearchResults extends Component {
  constructor (props) {
    super(props)
    this.toggleActiveBuero = this.toggleActiveBuero.bind(this)
  }

  componentDidMount () {
    this.props.actions.updateSort('flaeche', 'ASC') // set default sort
  }

  getTableTitle (label, title, sort, isNumeric = false) {
    const { sortClasses, sortOrder } = sort.field === label
      ? sort.order === 'ASC'
        ? { sortClasses: 'sortedby asc', sortOrder: 'DESC' }
        : { sortClasses: 'sortedby desc', sortOrder: 'ASC' }
      : { sortClasses: 'sortedby', sortOrder: 'DESC' }
    return (
      <th
        className={sortClasses + (isNumeric ? ' numeric' : '')}
        onClick={() => this.props.actions.updateSort(label, sortOrder)}>
        <span><Translate id={'BueroList/SearchResults/' + label}>{title}</Translate></span>
      </th>
    )
  }

  toggleActiveBuero (item) {
    const units = this.props.rows.filter((row, index) => {
      return index === item
    })[0]
    if (item !== this.props.activeBuero) {
      this.props.bueroListActions.setHighlightedUnits(units.einheitCodes)
      this.props.bueroListActions.setUnitsBeforeHover(units.einheitCodes)
    } else {
      this.props.bueroListActions.setHighlightedUnits([])
      this.props.bueroListActions.setUnitsBeforeHover([])
    }
    this.props.bueroListActions.toggleActiveBuero(item)
  }

  render () {
    const bueros = this.props.rows.map(
      (item, index) => {
        const result = index === this.props.activeBuero ? <ResultDetail buero={item} floorPlanImg={item.wohnungsCode} /> : null
        return (
          <Fragment key={item.einheit + '_' + index}>
            <ResultRow
              index={index}
              rowClicked={() => (this.toggleActiveBuero(index))}
              buero={item}
              active={index === this.props.activeBuero}
              updateIsometrie={this.props.bueroListActions.setHighlightedUnits}
              unitsBeforeHover={this.props.unitsBeforeHover}
            />
            {result}
          </Fragment>
        )
      }
    )

    const { sort } = this.props
    const sortFlaeche = this.getTableTitle('flaeche', 'Fläche', sort)
    const monthly = (<Fragment>m<sup>2</sup>/Mois</Fragment>)
    const sortMonthlyRate = this.getTableTitle('preis', monthly, sort, true)
    const sortYearly = this.getTableTitle('jahresmietzins', 'Jahresmietzins', sort, true)

    // rewrite highlightedUnits if search is changed and no activeBuero is yet set
    const highlightedUnits = this.props.rows[this.props.activeBuero] &&
    this.props.unitsBeforeHover.length === 0 &&
    this.props.highlightedUnits.length === 0
      ? this.props.rows[this.props.activeBuero].einheitCodes : this.props.highlightedUnits

    const isometrieStyles = { width: '95%' }
    let isometrieWrapperStyles = { position: 'sticky', top: '225px' }
    if (bueros.length === 0) {
      isometrieWrapperStyles = { top: '-50px' }
    } else if (bueros.length > 25) {
      isometrieWrapperStyles = { top: '50px' }
    }

    const translateIntro = <Translate id='BueroList/SearchResults/Intro'>Nous pouvons vous offrir l'espace de bureau suivant en fonction de votre sélection.</Translate>

    return (
      <section className='group bk-results'>
        <div className='group row' style={{ 'display': 'flex' }}>
          <div className='columns large-5 medium-5 small-12' style={isometrieWrapperStyles}>
            <Isometrie highlightedUnits={this.props.rows.length > 0 ? highlightedUnits : []} style={isometrieStyles} />
          </div>
          <div className='columns large-7 medium-7 small-12'>
            <p>{translateIntro}</p>
            <table className='table bk-results-table'>
              <thead>
                <tr>
                  <th>&nbsp;</th>
                  <th><Translate id='BueroList/SearchResults/etage'>Etage</Translate></th>
                  {sortFlaeche}
                  {sortMonthlyRate}
                  {sortYearly}
                </tr>
              </thead>
              <tbody>
                {bueros.length > 0 ? bueros : noBuerosFound}
              </tbody>
            </table>
          </div>
        </div>
      </section>
    )
  }
}

const noBuerosFound = (
  <tr className='bk-result-empty'>
    <td colSpan='7'><Translate id='BueroList/SearchResults/noBuerosFound'>Aucune option n'a été trouvée pour vos entrées. Veuillez nous contacter pour plus d'informations au +41 21 560 73 65</Translate></td>
  </tr>
)

const mapDispatchToProps = (dispatch) => {
  return {
    bueroListActions: bindActionCreators(actions, dispatch)
  }
}

const mapStateToProps = (state) => {
  return {
    activeBuero: state.wohnungList.filters.activeBuero,
    highlightedUnits: state.wohnungList.filters.highlightedUnits,
    unitsBeforeHover: state.wohnungList.filters.unitsBeforeHover
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchResults)
